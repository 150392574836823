@import 'src/ui/styles/variables';
@import 'src/ui/styles/core';
$listModifiers: 'primary', 'secondary', 'disabled', 'negative', 'positive';

@mixin setModifier($modifier) {
  $color: $color-3;
  $pointerEvents: auto;

  $background: $color-10;
  $backgroundHover: $color-16;
  $backgroundActive: $color-11;

  $border: 1px solid $color-10;
  $borderHover: 1px solid $color-10;
  $borderActive: 1px solid $color-11;

  $boxShadow: inset 0px 2px 0px rgba(254, 242, 205, 0.4), inset 0px -2px 0px rgba(180, 136, 4, 0.4);
  $boxShadowHover: 0px 2px 20px rgba(230, 174, 4, 0.6), inset 0px 2px 0px rgba(254, 242, 205, 0.4), inset 0px -2px 0px rgba(180, 137, 3, 0.4);
  $boxShadowActive: inset 0px 2px 0px rgba(254, 242, 205, 0.4), inset 0px -2px 0px rgba(180, 137, 3, 0.4);

  @if $modifier == 'secondary' {
    $background: $color-13;
    $backgroundHover: $color-15;
    $backgroundActive: $color-12;

    $border: 1px solid $color-13;
    $borderHover: 1px solid $color-15;
    $borderActive: 1px solid $color-12;

    $boxShadow: inset 0px 2px 0px rgba(254, 242, 205, 0.4), inset 0px -2px 0px rgba(180, 136, 4, 0.4);
    $boxShadowHover: 0px 2px 20px rgba(255, 128, 0, 0.6), inset 0px 2px 0px rgba(255, 204, 153, 0.4), inset 0px -2px 0px rgba(204, 102, 0, 0.4);
    $boxShadowActive: inset 0px 2px 0px rgba(255, 204, 153, 0.4), inset 0px -2px 0px rgba(204, 102, 0, 0.4);
  }
  @if $modifier == 'positive' {
    $color: $color-9;
    $background: $color-22;
    $backgroundHover: $color-23;
    $backgroundActive: $color-21;

    $border: 1px solid $color-22;
    $borderHover: 1px solid $color-23;
    $borderActive: 1px solid $color-21;

    $boxShadow: inset 0 2px 0 rgba(36, 144, 24, 0.4), inset 0 -2px 0 rgba(14, 57, 10, 0.4);
    $boxShadowHover: 0 2px 20px rgba(20, 78, 13, 0.6), inset 0 2px 0 rgba(36, 144, 24, 0.4), inset 0 -2px 0 rgba(14, 57, 10, 0.4);
    $boxShadowActive: inset 0 2px 0 rgba(36, 144, 24, 0.4), inset 0 -2px 0 rgba(14, 57, 10, 0.4);
  }
  @if $modifier == 'negative' {
    $color: $color-9;
    $background: $color-18;
    $backgroundHover: $color-19;
    $backgroundActive: $color-17;

    $boxShadow: inset 0px 2px 0px rgba(246, 80, 88, 0.4), inset 0px -2px 0px rgba(165, 9, 15, 0.4);
    $boxShadowHover: 0px 2px 20px rgba(189, 10, 18, 0.6), inset 0px 2px 0px rgba(246, 80, 88, 0.4), inset 0px -2px 0px rgba(189, 10, 18, 0.4);
    $boxShadowActive: inset 0px 2px 0px rgba(209, 10, 20, 0.4), inset 0px -2px 0px rgba(112, 6, 10, 0.4);

    $border: 1px solid $color-18;
    $borderHover: 1px solid $color-19;
    $borderActive: 1px solid $color-17;
  }
  @if $modifier == 'disabled' {
    $pointerEvents: none;
    $background: $color-5;
    $border: 1px solid $color-5;
    $boxShadow: inset 0px 2px 0px rgba(227, 218, 201, 0.4), inset 0px -2px 0px rgba(148, 124, 76, 0.4);
  }

  .text_button--#{$modifier} {
    background: $background;
    border: $border;
    box-shadow: $boxShadow;
    font-weight: bold;
    color: $color;
    pointer-events: $pointerEvents;
    @media (min-width: $width-3) {
      @include hover() {
        background: $backgroundHover;
        border: $borderHover;
        box-shadow: $boxShadowHover;
      }
    }
    &:active {
      background: $backgroundActive;
      border: $borderActive;
      box-shadow: $boxShadowActive;
    }
    transition: all 0.3s ease-in-out;
  }
}

@each $modifier in $listModifiers {
  @include setModifier($modifier);
}

.text_button {
  @include flexCentered;
  @include fontAverage;
  min-width: 110px;
  max-width: 220px;
  min-height: 26px;
  padding: 5px 10px;
  border-radius: $radius-big;
  cursor: pointer;
  text-align: center;
  @media (min-width: $width-1) {
    padding: 10px 20px;
  }
  @media (min-width: $width-4) {
    padding: 15px 25px;
    min-width: 230px;
  }
}