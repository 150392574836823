.canvas-container {
  width: 70%;
  height: 100%;
}

.canvas-container_stats {
  bottom: 3%;
  left: 2% !important;
  top: auto !important;
}
