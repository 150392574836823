@import 'src/ui/styles/variables';
@import 'src/ui/styles/core';
$listModifiers: 'expectation', 'performed', 'done', 'fail', 'disabled';

@mixin setModifier($modifier) {
  $pointerEvents: auto;

  $background: $color-10;
  $backgroundHover: $color-16;
  $backgroundActive: $color-11;

  $border: 1px solid $color-10;
  $borderHover: 1px solid $color-10;
  $borderActive: 1px solid $color-11;

  $boxShadow: inset 0px 2px 0px rgba(254, 242, 205, 0.4), inset 0px -2px 0px rgba(180, 136, 4, 0.4);
  $boxShadowHover: 0px 2px 20px rgba(230, 174, 4, 0.6), inset 0px 2px 0px rgba(254, 242, 205, 0.4), inset 0px -2px 0px rgba(180, 137, 3, 0.4);
  $boxShadowActive: inset 0px 2px 0px rgba(254, 242, 205, 0.4), inset 0px -2px 0px rgba(180, 137, 3, 0.4);

  @if $modifier == 'performed' {
    $pointerEvents: none;
    $background: $color-13;
    $border: 1px solid $color-13;
    $boxShadow: inset 0px 2px 0px rgba(255, 204, 153, 0.4), inset 0px -2px 0px rgba(204, 102, 0, 0.4);
  }
  @if $modifier == 'done' {
    $pointerEvents: none;
    $background: $color-24;
    $border: 1px solid $color-24;
    $boxShadow: inset 0 2px 0 #B8E7B1, inset 0 -2px 0 rgba(114, 207, 99, 0.4);
  }
  @if $modifier == 'fail' {
    $pointerEvents: none;
    $background: $color-20;
    $border: 1px solid $color-20;
    $boxShadow: inset 0 2px 0 rgba(255, 255, 255, 0.4), inset 0 -2px 0 rgba(255, 85, 51, 0.4);
  }
  @if $modifier == 'disabled' {
    $pointerEvents: none;
    $background: $color-5;
    $border: 1px solid $color-5;
    $boxShadow: inset 0 2px 0 rgba(227, 218, 201, 0.4), inset 0 -2px 0 rgba(148, 124, 76, 0.4);
  }

  .game_button--#{$modifier} {
    pointer-events: $pointerEvents;
    background: $background;
    border: $border;
    box-shadow: $boxShadow;
    color: $color-3;
    font-weight: bold;
    svg {
      width: 10px;
      height: 10px;
      @media (min-width: $width-2) {
        width: 15px;
        height: 15px;
      }
      @media (min-width: $width-4) {
        width: 25px;
        height: 25px;
      }
    }
    @media (min-width: $width-3) {
      @include hover() {
        background: $backgroundHover;
        border: $borderHover;
        box-shadow: $boxShadowHover;
      }
    }
    &:active {
      background: $backgroundActive;
      border: $borderActive;
      box-shadow: $boxShadowActive;
    }
    transition: all 0.3s ease-in-out;

  }
}

@each $modifier in $listModifiers {
  @include setModifier($modifier);
}

.game_button {
  display: flex;
  justify-content: space-between;
  align-items: center;
  @include fontAverage;
  min-width: 95px;
  max-width: 180px;
  min-height: 25px;
  border-radius: 15px;
  cursor: pointer;
  @media (min-width: $width-1) {
    min-width: 110px;
    min-height: 30px;
  }
  @media (min-width: $width-2) {
    min-width: 140px;
    min-height: 35px;
    border-radius: 20px;
  }
  @media (min-width: $width-4) {
    min-width: 230px;
    min-height: 60px;
    border-radius: 30px;
  }
}

.game_button-icon {
  @include flexCentered;
  padding-left: 5px;
  height: 100%;
  border-radius: 25px 0 0 25px;
  @media (min-width: $width-1) {
    padding-left: 8px;
  }
  @media (min-width: $width-2) {
    padding-left: 10px;
  }
  @media (min-width: $width-4) {
    padding-left: 20px;
    border-radius: 30px 0 0 30px;
  }
}

.game_button-title {
  width: 100%;
  text-align: center;
}