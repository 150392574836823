@import './src/ui/styles/variables';

@mixin hover() {
  @media (hover: hover) and (pointer: fine) {
    &:hover {
      @content;
    }
  }
}

.noselect {
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
  cursor: inherit;
}

.hidden {
  display: none;
}

@mixin flexCentered() {
  display: flex;
  justify-content: center;
  align-items: center;
}

//@mixin fontSmall
@mixin fontBig() {
  font-size: 14px;
  @media (min-width: $width-1) {
    font-size: 20px;
  }
  @media (min-width: $width-2) {
    font-size: 26px;
  }
  @media (min-width: $width-4) {
    font-size: 32px;
  }
}

@mixin fontAverage() {
  font-size: 10px;
  @media (min-width: $width-1) {
    font-size: 12px;
  }
  @media (min-width: $width-2) {
    font-size: 14px;
  }
  @media (min-width: $width-4) {
    font-size: 22px;
  }
}
