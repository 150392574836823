@import 'src/ui/styles/variables';
$listModifiers: 'active', 'deactivated';

@mixin setModifier($modifier) {
  $background: $color-4;
  $pointerEvents: auto;
  $left: 9%;
  $right: inherit;

  @if $modifier == 'active' {
    $background: $color-14;
    $left: inherit;
    $right: 9%;
  }

  .tumbler--#{$modifier} {
    background: $background;
    pointer-events: $pointerEvents;
    transition: background 0.2s ease-in-out;
    &:after {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      left: $left;
      right: $right;
      content: '';
      width: 12px;
      height: 12px;
      border-radius: 100%;
      background: white;
      transition: all 0.3s ease-in-out;
      @media (min-width: $width-2) {
        width: 15px;
        height: 15px;
      }
      @media (min-width: $width-4) {
        width: 20px;
        height: 20px;
      }
    }
  }
}

@each $modifier in $listModifiers {
  @include setModifier($modifier);
}

.tumbler {
  position: relative;
  width: 30px;
  height: 16px;
  border-radius: 15px;
  cursor: pointer;
  @media (min-width: $width-2) {
    width: 40px;
    height: 20px;
  }
  @media (min-width: $width-4) {
    width: 50px;
    height: 28px;
  }
}