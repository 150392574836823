@import 'src/ui/styles/variables';

.header_container {
  position: fixed;
  width: 100%;
  padding: 5px 0 5px 10px;
  z-index: $z-index-2;
  @media (min-width: $width-2) {
    padding: 10px 0 10px 15px;
  }
  @media (min-width: $width-4) {
    padding: 15px 0 15px 20px;
  }
}

.header_container--access {
  z-index: $z-index-4;
}