// color palette
$color-1: #5C5C5C;
$color-2: #2D2306;
$color-3: #3D3729;
$color-4: #605539;
$color-5: #C1AD86;
$color-6: #B6A78D;
$color-7: #E5D4B3;
$color-8: #FCEDCF;
$color-9: #FFF9E0;
$color-10: #FBC625;
$color-11: #DAA70B;
$color-12: #FF8000;
$color-13: #FF9114;
$color-14: #FF9933;
$color-15: #FFB561;
$color-16: #FCD36B;
$color-17: #A0080F;
$color-18: #D50B14;
$color-19: #ED0C16;
$color-20: #FFAA99;
$color-21: #1E4D19;
$color-22: #196411;
$color-23: #1F7A15;
$color-24: #95DB8A;
$color-25: #FBC728;
$color-26: #E6AE04;

// drop shadow
$drop-shadow-1: 0 4px 10px rgba(0, 0, 0, 0.15);
$drop-shadow-2: 0 4px 15px rgba(0, 0, 0, 0.08);
$drop-shadow-3: 0px 2px 10px 0px rgba(34, 60, 80, 0.25);
$drop-shadow-4: 0 -4px 15px rgba(0, 0, 0, 0.08), 0 4px 15px rgba(0, 0, 0, 0.08);
$drop-shadow-5: -5px 0px 20px 5px rgb(8 9 18 / 30%);

// border radius
$radius-small: 10px;
$radius-medium: 12px;
$radius-big: 30px;
$radius-circle: 50%;

// transition
$transition: all 0.3s ease 0s;

// font-size
$font-size-small: 10px;
$font-size-middle: 15px;
$font-size-large: 20px;

// z-index
$z-index-1: 0;
$z-index-2: 5;
$z-index-3: 10;
$z-index-4: 100;

// media
$width-1: 700px;
$width-2: 900px;
$width-3: 1200px;
$width-4: 1400px;