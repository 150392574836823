@import "src/ui/styles/variables";

.launcher {
    position: relative;
    background-image: url("../../../assets/media/background/main.jpg");
    background-repeat: no-repeat;
    background-size: cover;
}

.launcher-logo {
    width: 50%;
    height: auto;
}

.launcher-layout {
    display: flex;
    height: 100%;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
    transition: filter 0.3s ease-in-out;
}

.launcher-layout-children {
    width: 100%;
    max-width: 1400px;
    display: flex;
    justify-content: space-evenly;
}