@import './core';
@import './variables';


@font-face {
  font-family: 'Balsamiq';
  src: url('../../assets/fonts/Balsamiq.ttf') format('opentype');
  font-weight: normal;
  font-style: normal;
}

* {
  font-family: 'Balsamiq';
  letter-spacing: 1px;
  box-sizing: border-box;
  text-transform: uppercase;
  @media (max-width: $width-2) {
    letter-spacing: 0;
  }
  &::-webkit-scrollbar {
    width: 4px;
    height: 0;
    background: transparent;
  }

  &::-webkit-scrollbar-thumb {
    background: rgba(0, 0, 0, 0.35);
    border-radius: 10px;
    box-shadow: rgba(255, 255, 255, 0.3) 0 0 0 1px;
  }

  &:hover::-webkit-scrollbar-thumb {
    background: rgba(0, 0, 0, 0.45);
  }

  &::-webkit-scrollbar-thumb:hover {
    background: rgba(0, 0, 0, 0.55);
  }
}

html {
  overflow-y: hidden;
  overflow-x: hidden;
  overscroll-behavior: none;
}

body {
  margin: 0;
  user-select: none;
  overflow-x: hidden;
  overflow-y: hidden;
  width: 100%;
  height: 100vh; /* Use vh as a fallback for browsers that do not support Custom Properties */
  height: calc(var(--vh, 1vh) * 100);
}

#root {
  width: 100%;
  height: 100%;
}

.app-layout {
  width: 100%;
  height: 100%;
  background: $color-1;
}

.wrapper-static {
  display: block;
  width: 100%;
  height: 100%;
}