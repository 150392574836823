@import 'src/ui/styles/variables';
@import 'src/ui/styles/core';

.game-card {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  border-radius: 15px;
  background: $color-9;
  box-shadow: $drop-shadow-1;
  transition: all 0.3s ease-in-out;
  padding: 8px;
  @media (min-width: $width-1) {
    padding: 10px 15px;
  }
  @media (min-width: $width-2) {
    padding: 15px 20px;
  }
  @media (min-width: $width-3) {
    padding: 25px 35px;
  }
  @media (min-width: $width-4) {
    padding: 30px 40px;
  }

  span {
    @include fontBig;
    font-weight: bold;
    width: 100%;
    color: $color-3;
    text-align: center;
    margin-bottom: 10px;
    @media (min-width: $width-1) {
      margin-bottom: 20px;
    }
    @media (min-width: $width-2) {
      margin-bottom: 25px;
    }
    @media (min-width: $width-3) {
      margin-bottom: 40px;
    }
    @media (min-width: $width-4) {
      margin-bottom: 50px;
    }
  }
  .text_button {
    width: 100%;
    margin: 2px;
    @media (min-width: $width-1) {
      margin-bottom: 3px;
    }
    @media (min-width: $width-2) {
      margin-bottom: 4px;
    }
    @media (min-width: $width-4) {
      margin-bottom: 5px;
    }
  }
}

.game-card--blocked {
  opacity: 0.8;
}