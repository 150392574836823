@import 'src/ui/styles/variables';
@import 'src/ui/styles/core';

.rotate-container {
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(26, 26, 26, 0.9);
  z-index: $z-index-4;
  display: none;
  @include flexCentered;
  flex-direction: column;
}
.rotate-container_icon {
  margin: 30px;
}

.rotate-container_info {
  @include fontBig;
  color: $color-9;
}