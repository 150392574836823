@import 'src/ui/styles/variables';
@import 'src/ui/styles/core';
$listModifiers: 'primary', 'secondary', 'disabled', 'without_background';

@mixin setModifier($modifier) {
  $pointerEvents: auto;

  $background: $color-14;
  $backgroundHover: $color-15;
  $backgroundActive: $color-13;

  $boxShadow: inset 0px -2px 0px rgba(255, 145, 20, 0.4), inset 0px 2px 0px rgba(255, 193, 122, 0.4);
  $boxShadowHover: 0 2px 20px rgba(250, 133, 0, 0.6), inset 0px -2px 0px rgba(255, 157, 46, 0.4), inset 0px 2px 0px rgba(255, 205, 148, 0.4);
  $boxShadowActive: inset 0px -2px 0px rgba(224, 120, 0, 0.4), inset 0px 2px 0px rgba(255, 169, 71, 0.4);

  $border: 1px solid $color-14;
  $borderHover: 1px solid $color-15;
  $borderActive: 1px solid $color-13;

  $fillPath: $color-8;

  @if $modifier == 'secondary' {
    $background: $color-18;
    $backgroundHover: $color-19;
    $backgroundActive: $color-17;

    $boxShadow: inset 0px 2px 0px rgba(246, 80, 88, 0.4), inset 0px -2px 0px rgba(165, 9, 15, 0.4);
    $boxShadowHover: 0px 2px 20px rgba(189, 10, 18, 0.6), inset 0px 2px 0px rgba(246, 80, 88, 0.4), inset 0px -2px 0px rgba(189, 10, 18, 0.4);
    $boxShadowActive: inset 0px 2px 0px rgba(209, 10, 20, 0.4), inset 0px -2px 0px rgba(112, 6, 10, 0.4);

    $border: 1px solid $color-18;
    $borderHover: 1px solid $color-19;
    $borderActive: 1px solid $color-17;
  }
  @if $modifier == 'without_background' {
    $background: none;
    $backgroundHover: none;
    $backgroundActive: none;

    $boxShadow: none;
    $boxShadowHover: none;
    $boxShadowActive: none;

    $border: none;
    $borderHover: none;
    $borderActive: none;

    $fillPath: $color-4;
  }
  @if $modifier == 'disabled' {
    $background: $color-5;
    $boxShadow: inset 0px 2px 0px rgba(227, 218, 201, 0.4), inset 0px -2px 0px rgba(148, 124, 76, 0.4);
    $border: 1px solid $color-5;
    $fillPath: $color-4;

    $pointerEvents: none;
  }

  .icon_button--#{$modifier} {
    background: $background;
    box-shadow: $boxShadow;
    pointer-events: $pointerEvents;
    border: $border;
    path {
      fill: $fillPath;
    }
    @media (min-width: $width-3) {
      @include hover() {
        background: $backgroundHover;
        box-shadow: $boxShadowHover;
        border: $borderHover;
      }
    }
    &:active {
      background: $backgroundActive;
      box-shadow: $boxShadowActive;
      border: $borderActive;
    }
    transition: all 0.3s ease-in-out;
  }
}

@each $modifier in $listModifiers {
  @include setModifier($modifier);
}

.icon_button {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  width: 28px;
  height: 28px;
  padding: 5px;
  border-radius: $radius-circle;
  cursor: pointer;
  svg {
    width: 15px;
  }
  @media (min-width: $width-2) {
    width: 40px;
    height: 40px;
    svg {
      width: 20px;
    }
  }
  @media (min-width: $width-4) {
    width: 50px;
    height: 50px;
    svg {
      width: 30px;
    }
  }
}