@import 'src/ui/styles/variables';
@import 'src/ui/styles/core';

.modal--setting {
  .modal_container {
    padding-top: 62px;
    @media (min-width: $width-1) {
      padding-top: 74px;
    }
    @media (min-width: $width-2) {
      padding-top: 86px;
    }
    @media (min-width: $width-4) {
      padding-top: 109px;
    }
  }
}

.setting_item {
  display: grid;
  grid-template-columns: 0.15fr 1fr;
  min-height: 40px;
  align-items: center;
  cursor: pointer;
  color: $color-4;
  font-weight: bold;
  padding: 5px;
  border-bottom: 1px solid $color-7;
  &:first-child {
    border-top: 1px solid $color-7;
  }
  :nth-child(1) {
    justify-self: center;
  }
  :nth-child(2) {
    justify-self: left;
  }
  @media (min-width: $width-3) {
    @include hover() {
      background: $color-7;
    }
  }
  &:active {
    background: $color-6;
  }
  @media (min-width: $width-2) {
    min-height: 55px;
  }
  @media (min-width: $width-4) {
    min-height: 65px;
  }
}

.setting_item-text {
  @include fontAverage;
  margin-left: 5px;
}