@import 'src/ui/styles/variables';
@import 'src/ui/styles/core';

.modal-overlay {
  z-index: $z-index-3;
  background: rgba(26, 26, 26, 0.6);
  position: fixed;
  top: 50%;
  left: 0;
  right: 0;
  height: calc(var(--vh, 1vh) * 100);
  transform: translate(0, -50%);
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal {
  position: relative;
  outline: none;
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal_container {
  width: 100%;
  display: flex;
  flex-direction: column;
  background: $color-9;
  padding: 15px;
  border-radius: 15px;
  @media (min-width: $width-1) {
    padding: 20px;
  }
  @media (min-width: $width-2) {
    padding: 25px;
  }
  @media (min-width: $width-4) {
    padding: 40px;
  }
  position: relative;
}

.modal_header {
  @include fontBig;
  @include flexCentered;
  font-weight: bold;
  color: $color-2;
  position: absolute;
  top: 0;
  left: 0;
  background: $color-14;
  width: 100%;
  padding: 15px;
  border-top-left-radius: $font-size-middle;
  border-top-right-radius: $font-size-middle;
}

.modal_body {
  @include flexCentered;
  -webkit-overflow-scrolling: touch;
  text-align: center;
  overflow: auto;
  @media (max-width: $width-4) {
    overflow-y: visible;
  }
  scrollbar-color: rgba(0, 0, 0, 0.35); // firefox
  scrollbar-width: none; // firefox
  &::-webkit-scrollbar {
    width: 4px;
    height: 0;
    background: transparent;
  }
  &::-webkit-scrollbar-thumb {
    background: rgba(0, 0, 0, 0.35);
    border-radius: 10px;
    box-shadow: rgba(255, 255, 255, 0.3) 0 0 0 1px;
  }
  &:hover::-webkit-scrollbar-thumb {
    background: rgba(0, 0, 0, 0.45);
  }
  &::-webkit-scrollbar-thumb:hover {
    background: rgba(0, 0, 0, 0.55);
  }
}

.modal_body-content {
  width: 100%;
}

.modal_body-header {
  @include fontBig();
  font-weight: bold;
  text-align: center;
  color: $color-2;
  margin-bottom: 10px;
  padding: 0 10px;
  @media (min-width: $width-2) {
    padding: 0 15px;
    margin-bottom: 13px;
  }
  @media (min-width: $width-4) {
    padding: 0 20px;
    margin-bottom: 15px;
  }
}

.modal_body-info {
  padding: 0 8px;
  @media (min-width: $width-2) {
    padding: 0 12px;
  }
  @media (min-width: $width-4) {
    padding: 0 15px;
  }
  img {
    margin: 0 3px;
    height: 15px;
    vertical-align: top;
    @media (min-width: $width-2) {
      margin: 0 5px;
      height: 20px;
    }
    @media (min-width: $width-3) {
      height: 25px;
    }
    @media (min-width: $width-4) {
      height: 30px;
    }
  }
  > span {
    @include fontAverage();
    line-height: 20px;
    @media (min-width: $width-3) {
      line-height: 25px;
    }
    @media (min-width: $width-4) {
      line-height: 30px;
    }
  }
}

.modal_actions {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 10px;
  .text_button:nth-child(2) {
    margin-left: 15px;
    @media (min-width: $width-2) {
      margin-left: 25px;
    }
    @media (min-width: $width-4) {
      margin-left: 50px;
    }
  }
  @media (min-width: $width-2) {
    margin-top: 13px;
  }
  @media (min-width: $width-4) {
    margin-top: 15px;
  }
}

.modal_media {
  min-width: 200px;
  max-width: 200px;
  height: 150px;
  @media (max-height: 300px) {
    min-width: 130px;
    max-width: 130px;
    height: 100px;
  }
  @media (min-width: $width-2) {
    min-width: 230px;
    max-width: 230px;
    height: 175px;
  }
  @media (min-width: $width-3) {
    min-width: 300px;
    max-width: 300px;
    height: 230px;
  }
  @media (min-width: $width-4) {
    min-width: 400px;
    max-width: 400px;
    height: 300px;
  }
  > img, video {
    width: 100%;
    border-radius: 10px;
  }
}

.modal--size-default {
  width: 315px;

  .modal_title-exit {
    position: absolute;
    top: -12px;
    right: -12px;
  }

  @media (min-width: $width-2) {
    width: 470px;
    .modal_title-exit {
      top: -18px;
      right: -18px;
    }
  }
  @media (min-width: $width-4) {
    width: 670px;
    .modal_title-exit {
      top: -23px;
      right: -23px;
    }
  }
}

.modal--size-big {
  width: 530px;

  .modal_title-exit {
    position: absolute;
    top: -12px;
    right: -12px;
  }

  @media (min-width: $width-2) {
    width: 800px;
    .modal_title-exit {
      top: -18px;
      right: -18px;
    }
  }
  @media (min-width: $width-4) {
    width: 1200px;
    .modal_title-exit {
      top: -23px;
      right: -23px;
    }
  }
}