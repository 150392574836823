@import 'src/ui/styles/variables';
@import 'src/ui/styles/core';
$listModifiers: 'empty', 'action', 'successfully', 'failed', 'disabled';

.control-panel {
  position: absolute;
  top: 0;
  right: 0;
  width: 35%;
  height: 100%;
  box-shadow: $drop-shadow-5;
  display: grid;
  grid-template-columns: 1fr 1fr;
  border-radius: 30px 0 0 30px;
  grid-template-rows: 0.15fr 1fr 0.15fr;
  grid-auto-columns: 1fr;
  gap: 0 0;
  grid-auto-flow: row;
  grid-template-areas:
    "header-1 header-1"
    "main-1 main-1"
    "footer footer";
  @media (min-width: $width-1) {
    border-radius: 40px 0 0 40px;
  }
  @media (min-width: $width-4) {
    border-radius: 50px 0 0 50px;
  }
}

.control-panel--column {
  grid-template-areas:
    "header-1 header-2"
    "main-1 main-2"
    "footer footer";
  .control-panel_content, .control-panel_content--stack {
    justify-content: flex-start;
  }
}

.control-panel_header {
  grid-area: header-1;
  @include fontBig;
  @include flexCentered;
  width: 100%;
  height: 100%;
  border-top-left-radius: 20px;
  background: $color-25;
  border-right: 1px solid $color-26;
  font-weight: bold;
  color: $color-3;
  padding: 10px 0;
  @media (min-width: $width-4) {
    border-top-left-radius: 30px;
  }
}

.control-panel_header--stack {
  grid-area: header-2;
  @include fontBig;
  @include flexCentered;
  width: 100%;
  height: 100%;
  background: $color-25;
  border-left: 1px solid $color-26;
  font-weight: bold;
  color: $color-3;
  padding: 10px 0;
}

.control-panel_content {
  grid-area: main-1;
  border-right: 1px solid $color-26;
  background: $color-9;
}

.control-panel_content--stack {
  grid-area: main-2;
  border-left: 1px solid $color-26;
  background: $color-9;
}

.control-panel_content--stack-disabled > .game_button {
  pointer-events: none;
}

.control-panel_content, .control-panel_content--stack {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  padding: 10px 5px;
  overflow: auto;
  .game_button {
    margin: 4px 0;
  }
}

.control-panel_footer {
  grid-area: footer;
  @include flexCentered;
  width: 100%;
  height: 100%;
  background: $color-25;
  padding: 10px 0;
  border-bottom-left-radius: 20px;
  @media (min-width: $width-4) {
    border-bottom-left-radius: 30px;
  }
  .text_button:first-child {
    margin-right: 10px;
    @media (min-width: $width-1) {
      margin-right: 15px;
    }
    @media (min-width: $width-2) {
      margin-right: 20px;
    }
    @media (min-width: $width-3) {
      margin-right: 30px;
    }
    @media (min-width: $width-4) {
      margin-right: 40px;
    }
  }
}